import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import tbFormConfig from "./tbFormConfig";

console.log("creating eb widget");
window.EBWidgets.createWidget({
    widgetType: 'checkout',
    eventId: tbFormConfig.ebEventId,
    modal: true,
    modalTriggerElementId: 'eventbrite-widget-modal-trigger',
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
